<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="table"
    isTree
    :hasPage="false"
    resource="/usenature/list"
    :transformResponse="handleTransformResponse"
    :loading="loading"
    :filterFields="[]"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList")
  el-drawer(
    :title="formTitle"
    size="70%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
    DrawerForm(
      ref="drawerForm"
      v-model="formData"
      :initFormData="initFormData"
      :subType="formSubType"
      @success="handleSuccess")
  </template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import cloneDeep from 'lodash/cloneDeep'
import DrawerForm from './components/DrawerForm.vue'

export default {
  name: 'UsenatureManage',
  components: { DrawerForm },
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '用水性质管理'
      }
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/file/usenatureManage/add' },
      { name: 'edit', url: '/file/usenatureManage/edit' },
      { name: 'delete', url: '/file/usenatureManage/delete' }
    ])
    return {
      showTable: false,
      // 表格是否loading
      loading: false,
      // 表格列配置
      columns: [{
        name: 'usenature',
        label: '名称'
      }, {
        name: 'priceId',
        label: '价格方案',
        relation: 'price',
        render: { type: 'select' }
      }, {
        name: 'remark',
        label: '备注'
      }],
      toolbarButtonList: actionRights.add ? [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.initFormData = {
            remark: '',
            usenature: '',
            priceId: null,
            parentId: null
          }
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }] : [],
      operateButtonList: [{
        label: '新增',
        name: 'addChild',
        type: 'text',
        textType: 'primary',
        showabled: (data) => actionRights.add && !data.parentId,
        func: ({ data }) => {
          this.initFormData = {
            remark: '',
            usenature: '',
            priceId: null,
            parentId: data.id
          }
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }, {
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          this.initFormData = cloneDeep(data)
          this.formData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        showabled: actionRights.delete,
        func: this.handleDelete
      }],
      // 侧拉表单是否展开
      drawer: false,
      // 表单数据
      formData: {},
      initFormData: {},
      // 表单提交类型
      formSubType: '' // add/edit
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['price'])
        .then(res => {
          renderRelationColumns(res, [this.columns])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData () {
      this.$refs.table.reRenderTableData()
    },
    handleTransformResponse (response) {
      response.data = {
        total: response.data.length,
        data: response.data
      }
      return response
    },
    handleDrawerOpen () {
      this.$nextTick(() => {
        this.$refs.drawerForm.resetForm()
      })
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$confirm('是否确认删除', '提示')
          .then(() => {
            this.$httpDelete({
              url: `/usenature/delete/${data.id}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                  return
                }
                this.$message.success('删除成功!')
                this.getData()
                resolve('success')
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    handleSuccess () {
      this.handleClose()
      this.getData()
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>
  <style lang="sass" scoped>
  .wf-table
    display: flex
    flex: 1
    flex-direction: column
    background-color: #eee
    width: 100%
    height: 100%
  .drawerForm
    padding: 8px
  </style>
