<template lang="pug">
.full-box.drawerForm
  DataForm(
    ref="form"
    v-model="formData"
    type="grid"
    :gridspanNum="3"
    gridFlow="row"
    inlineGrid
    :formFields="formFields")
  .form-item-box
    .table-box
      label.blue-header 选择价格方案
      .price-table
        DataTable(
          ref="table"
          highlightClickRow
          :hasPage="false"
          resource="/price/list"
          :settingButtonList="[]"
          :transformResponse="handleTransformResponse"
          :filterFields="[]"
          :columns="columns"
          @current-change="handleCurrentChange"
          @afterGetAllData="handleAfterGetAllData")
    .price-box
      label.blue-header 价格详情
      .info-box
        .empty(v-if="!rowData.id") 请选择左侧价格方案
        DataInfo(
          v-if="rowData.id && !rowData.stepwise"
          :data="rowData"
          :fields="normalFields")
        el-collapse(
          v-if="rowData.id && rowData.stepwise"
          v-model="collapse")
          el-collapse-item(
            :title="`水量区间：0-${rowData.quantity1}m³`"
            name="1")
            DataInfo(
              :data="rowData"
              :fields="stepwiseFieldsObj.level1")
          el-collapse-item(
            :title="`水量区间：${rowData.quantity1}-${rowData.quantity2}m³`"
            name="2")
            DataInfo(
              :data="rowData"
              :fields="stepwiseFieldsObj.level2")
          el-collapse-item(
            :title="`水量区间：${rowData.quantity2}-${rowData.quantity3}m³`"
            name="3")
            DataInfo(
              :data="rowData"
              :fields="stepwiseFieldsObj.level3")
  ButtonGroup.form-button(:buttonList="buttonList")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import priceMixin from '@/views/FileManage/PriceManage/mixin/common.js'

export default {
  name: 'DrawerForm',
  mixins: [priceMixin],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    // 表单类型add/edit
    subType: {
      type: String,
      default: ''
    },
    initFormData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    submitApiFuncName () {
      return this.submitApiFuncMap.get(this.subType)
    }
  },
  data () {
    return {
      formFields: [{
        name: 'usenature',
        label: '名称',
        form: {
          tag: 'input',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'remark',
        label: '备注',
        form: {
          tag: 'input',
          colSpan: 2,
          inputType: 'textarea'
        }
      }],
      collapse: ['1', '2', '3'],
      stepwiseFieldsObj: [
        { name: 'water', label: '水费' },
        { name: 'sewage', label: '污水费' },
        { name: 'tax', label: '水资源费' }
      ].reduce((res, item) => {
        for (let i = 1; i < 4; i++) {
          res[`level${i}`].push({
            name: `${item.name}${i}`,
            label: item.label,
            render: { type: 'padString', suffix: '元' }
          })
        }
        return res
      }, {
        level1: [],
        level2: [],
        level3: []
      }),
      rowData: {},
      submitApiFuncMap: new Map([
        ['add', 'handleAdd'],
        ['edit', 'handleEdit']
      ]),
      buttonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: this.handleSubmit
      }, {
        label: '重置',
        func: this.resetForm
      }]
    }
  },
  methods: {
    // 解决第一次打开是修改类型时，dom没加载上，选中不上的问题
    handleAfterGetAllData () {
      this.$nextTick(() => {
        this.$refs.table.setCurrentRowByKey(this.initFormData.priceId)
      })
    },
    handleCurrentChange (row) {
      this.rowData = row || {}
      this.formData.priceId = this.rowData.id || null
    },
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.$nextTick(() => {
        this.$refs.table.setCurrentRowByKey(this.initFormData.priceId)
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataform.clearValidate()
      })
    },
    async handleSubmit () {
      try {
        await this.$refs.form.$refs.dataform.validate()
        if (!this.formData.priceId) {
          this.$message.warning('请选择价格方案')
          return
        }
        if (!this.submitApiFuncName) return
        const result = await this[this.submitApiFuncName](this.formData)
        return result
      } catch (e) {
        return e
      }
    },
    handleAdd (formData) {
      return new Promise((resolve, reject) => {
        this.$post({
          url: '/usenature/save',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.$emit('success')
          })
          .catch(reject)
      })
    },
    handleEdit (formData) {
      return new Promise((resolve, reject) => {
        const { id, usenature, remark, parentId, priceId } = formData
        this.$patch({
          url: `/usenature/update/${id}`,
          data: {
            id, usenature, remark, parentId, priceId
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.$emit('success')
          })
          .catch(reject)
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="sass" scoped>
.drawerForm
  padding: 8px
  .blue-header
    margin-bottom: 8px
  .form-button
    margin: 8px 0
  .form-item-box
    width: 100%
    height: calc( 100% - 190px )
    display: flex
    flex-direction: row
    .table-box
      width: calc( 100% - 248px )
      height: 100%
      .price-table
        width: 100%
        height: calc( 100% - 28px )
    .price-box
      width: 240px
      height: calc( 100% - 16px )
      margin-left: 8px
      padding: 8px
      background-color: #fff
      .empty
        padding: 8px 0
      .info-box
        height: calc( 100% - 40px )
        overflow-y: auto
        padding-top: 8px
</style>
